import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, switchMap, withLatestFrom } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { StorageService } from 'src/app/account/artifacts/storage.service';
import { AccountService } from 'src/app/account/store/services/account.service';
import { logoutSuccess } from 'src/app/account/store/state/account.actions';
import * as AccountActions from 'src/app/account/store/state/account.actions';
import { getRedirectUrl } from 'src/app/account/store/state/account.selectors';

@Injectable()
export class AccountEffects {
  constructor(private actions$: Actions, private accountService: AccountService, private storage: StorageService, private router: Router, private store: Store) {}

  verifyEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.verifyEmail),
      switchMap(({ params }) => {
        return this.accountService.verifyEmail(params).pipe(
          map(() => AccountActions.verifyEmailSuccess()),
          catchError(() => of(AccountActions.verifyEmailFail())),
        );
      })
    );
  });

  signup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.signup),
      switchMap(({ data }) => {
        return this.accountService.signup(data).pipe(
          map(() => AccountActions.signupSuccess()),
          catchError((error) => of(AccountActions.signupFail({ error }))),
        );
      })
    );
  });

  signupRetry$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.signupRetry),
      switchMap(({ data }) => {
        return this.accountService.signupRetry(data).pipe(
          map(() => AccountActions.signupRetrySuccess()),
          catchError(() => of(AccountActions.signupRetryFail())),
        );
      })
    );
  });

  resendVerifyEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.resendVerifyEmail),
      switchMap(({ email }) => {
        return this.accountService.resendVerifyEmail(email).pipe(
          map(() => AccountActions.resendVerifyEmailSuccess()),
          catchError(() => of(AccountActions.resendVerifyEmailFail())),
        );
      })
    );
  });

  sendResetPasswordRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.sendResetPasswordRequest),
      switchMap(({ email }) => {
        return this.accountService.sendResetPasswordRequest(email).pipe(
          map(() => AccountActions.sendResetPasswordRequestSuccess()),
          catchError(() => of(AccountActions.sendResetPasswordRequestFail())),
        );
      })
    );
  });

  resetPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.resetPassword),
      switchMap(({ params, newPassword }) => {
        return this.accountService.resetPassword(params, newPassword).pipe(
          map(() => AccountActions.resetPasswordSuccess()),
          catchError(() => of(AccountActions.resetPasswordFail())),
        );
      })
    );
  });

  login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.login),
      switchMap(({ data }) => {
        return this.accountService.login(data).pipe(
          map((user) => AccountActions.loginSuccess({ user })),
          catchError((error) => of(AccountActions.loginFail({ error: error.error }))),
        );
      })
    );
  });

  loginSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.loginSuccess),
      withLatestFrom(this.store.select(getRedirectUrl).pipe(take(1))),
      tap(([{ user }, redirectUrl]) => {
        this.storage.setItem('TOKEN', user.token);
        this.storage.setItem('INFO', JSON.stringify(user));

        if (user.user_type === 'SU' ) {
          if (redirectUrl) {
            this.router.navigate([redirectUrl]);
          } else {
            this.router.navigate(['./app']);
          }
        } else if (user.user_type === 'AD' && user.admin_subscribed !== null) {
          if (redirectUrl) {
            this.router.navigate([redirectUrl]);
          } else {
            this.router.navigate(['./app']);
          }
        } else {
          this.router.navigate(['./activate']);
        }
      }),
    );
  }, { dispatch: false });

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.logout),
      tap(() => {
        this.storage.clear();
      }),
      map(() => logoutSuccess()),
    );
  });

  logoutSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.logoutSuccess),
      tap(() => {
        this.router.navigate(['./account/login']);
      }),
    );
  }, { dispatch: false });


  createSubUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.createUser),
      switchMap(({ token, user }) => {
        return this.accountService.createUser(token, user).pipe(
          map(() => AccountActions.createUserSuccess()),
          catchError((error) => of(AccountActions.createUserFail({ error }))),
        );
      })
    );
  });

  getProducts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.getProducts),
      switchMap(() => {
        return this.accountService.getProducts().pipe(
          map((products) => AccountActions.getProductsSuccess({ products })),
          catchError(() => of(AccountActions.getProductsFail())),
        );
      })
    );
  });

  getSubscriptionPlan$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.getSubscriptionPlan),
      switchMap(() => {
        return this.accountService.getSubscriptionPlan().pipe(
          map((subscriptionPlan) => AccountActions.getSubscriptionPlanSuccess({ subscriptionPlan })),
          catchError(() => of(AccountActions.getSubscriptionPlanFail())),
        );
      })
    );
  });
}
