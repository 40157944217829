import { createAction, props } from '@ngrx/store';
import { LoginResp } from 'src/app/account/store/models/login.interface';
import { CardInfo } from 'src/app/dashboard/settings/store/models/card-info.interface';
import { ChangePassword } from 'src/app/dashboard/settings/store/models/change-password.interface';
import { Payment } from 'src/app/dashboard/settings/store/models/payment.interface';
import { Profile } from 'src/app/dashboard/store/models/profile.interface';
import { IProduct, UserInfo } from 'src/app/shared/interface/cardinformation.model';
import { Scores, ScoresParams, Weights, WeightsParams } from '../models/flexible-risk.interface';

export const getAdminUserDetails = createAction('[Settings] get admin user details');

export const getAdminUserDetailsSuccess = createAction('[Settings] get admin user details success', props<{ profile: Profile }>());

export const getAdminUserDetailsFail = createAction('[Settings] get admin user details fail');

export const getProducts = createAction('[Settings] get products'); //here

export const getProductsSuccess = createAction('[Settings] get products success', props<{ products: IProduct[] }>()); //here

export const getProductsFail = createAction('[Settings] get products fail'); //here

export const getSubscriptionPlan = createAction('[Settings] get subscription'); //here

export const getSubscriptionPlanSuccess = createAction('[Settings] get subscription success', props<{ subscriptionPlan: any }>()); //here

export const getSubscriptionPlanFail = createAction('[Settings] get subscription fail'); //here

export const getCardDetails = createAction('[Settings] get card details');

export const getCardDetailsSuccess = createAction('[Settings] get card details success', props<{ cardDetails: any }>());

export const getCardDetailsFail = createAction('[Settings] get card details fail');

export const setDefaultCard = createAction('[Settings] set default card', props<{ card_id: string }>());

export const setDefaultCardSuccess = createAction('[Settings] set default card success');

export const setDefaultCardFail = createAction('[Settings] set default card fail');

export const deleteCard = createAction('[Settings] delete card', props<{ card_id: string }>());

export const deleteCardSuccess = createAction('[Settings] delete card success');

export const deleteCardFail = createAction('[Settings] delete card fail');

export const addCard = createAction('[Settings] add card', props<{ cardInfo: CardInfo }>());

export const addCardSuccess = createAction('[Settings] add card success', props<{ cardResponse: any }>());

export const addCardFail = createAction('[Settings] add card fail');

export const addSubscription = createAction('[Settings] add subscription', props<{ userInformation: UserInfo, updateUserInfo?: LoginResp }>());

export const addSubscriptionSuccess = createAction('[Settings] add subscription success', props<{ subscriptionResponse: any, updateUserInfo?: LoginResp }>());

export const addSubscriptionFail = createAction('[Settings] add subscription fail');

export const getPaymentHistory = createAction('[Settings] get payment history', props<{ page: number, size: number }>());

export const getPaymentHistorySuccess = createAction('[Settings] get payment history success', props<{ paymentHistory: Payment}>());

export const getPaymentHistoryFail = createAction('[Settings] get payment history fail');

export const changePassword = createAction('[Settings] change password', props<{ password: ChangePassword }>());

export const changePasswordSuccess = createAction('[Settings] change password success');

export const changePasswordFail = createAction('[Settings] change password fail');

export const editProfile = createAction('[Settings] edit profile', props<{ formData: FormData }>());

export const editProfileSuccess = createAction('[Settings] edit profile success');

export const editProfileFail = createAction('[Settings] edit profile fail');

export const getImage = createAction('[Settings] get image', props<{ imageUrl: string }>());

export const getImageSuccess = createAction('[Settings] get image success', props<{ image: Blob }>());

export const getImageFail = createAction('[Settings] get image fail');

export const getWeights = createAction ('[Settings] get flexible risk weights');

export const getWeightsSuccess = createAction ('[Settings] get flexible risk weights success', props<{weight: WeightsParams}>());

export const getWeightsFail = createAction ('[Settings] get flexible risk weights fail');

export const openIndividualScore = createAction ('[Settings] open individual score details', props<{params: ScoresParams}>());

export const openIndividualScoreSuccess = createAction ('[Settings] open individual score details success', props<{scores: Scores[]}>());

export const openIndividualScoreFail = createAction ('[Settings] open individual score details fail');

export const openCompanyScore = createAction ('[Settings] open company score details', props<{params: ScoresParams}>());

export const openCompanyScoreSuccess = createAction ('[Settings] open company score details success', props<{scores: Scores[]}>());

export const openCompanyScoreFail = createAction ('[Settings] open company score details fail');

export const postWeights = createAction ('[Settings] update risk weight', props<{weight_type: string, parameters: Weights[]}>());

export const postWeightsSuccess = createAction ('[Settings] update risk weight success');

export const postWeightsFail = createAction ('[Settings] update risk weight fail');

export const postScores = createAction ('[Settings] update risk score', props<{params: ScoresParams, parameters: Scores[]}>());

export const postSocresSuccess = createAction ('[Settings] update risk score success');

export const postScoresFail = createAction ('[Settings] update risk score fail');

export const resetFlexible = createAction('[Settings] reset flexible search');





