import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// import 'frappe-charts/dist/frappe-charts.min.esm.js';

if (environment.production) {
  enableProdMode();
}

console.log(`App Version: ${environment.version}, Production: ${environment.production}, Subdomain: ${environment.subdomain}`);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
