import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StorageService } from './account/artifacts/storage.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'dx-portal';

  private readonly destroyed$ = new Subject();

  constructor(
    private transloco: TranslocoService,
    @Inject(DOCUMENT) private document: Document,
    private storage: StorageService,
  ) {}

  ngOnInit(): void {
    this.setInitialLanguage();

    this.transloco.langChanges$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(resp => {
        this.changeLayout(resp);
      });
  }


  changeLayout(lang:string) {
    const htmlTag = this.document.documentElement;
    htmlTag.dir = lang === 'ar' ? 'rtl' : 'ltr';
    htmlTag.lang = lang;

    this.storage.setItem('LANG', lang);
  }

  setInitialLanguage() {
    const lng = this.storage.getItem('LANG');

    if (lng) {
      this.transloco.setActiveLang(lng);
    }
  }

  ngOnDestroy () {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
