import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, switchMap, throwError } from 'rxjs';
import { logout } from 'src/app/account/store/state/account.actions';
import { getToken } from 'src/app/account/store/state/account.selectors';
import { catchError, first } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  private readonly urlsToNotUse = ['user/login/'];

  constructor(private router: Router, private store: Store) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(getToken).pipe(
      first(),
      switchMap((token) => {
        if (this.needsInterceptor(req.url)) {
          if (token) {
            req = req.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`
              }
            });
          }
        }

        return next.handle(req).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error && error.status === HttpStatusCode.Unauthorized) {
              this.store.dispatch(logout());
            }

            // if (error.status === 0 || error.status === 500) {
            //   error = 'Internal Server Error';
            //   return throwError(()=>new Error ('Internal Server Error'));
            // }

            return throwError(() => error);
            // return throwError(() => new Error(error.error));
          })
        );
      }),
    );
  }

  private needsInterceptor(url: string): boolean {
    return !this.urlsToNotUse.find(path => url.includes(path));
  }
}
