import { ParamMap } from '@angular/router';
import { createReducer, on } from '@ngrx/store';
import { BackPath } from 'src/app/account/store/models/back-path.interface';
import { LoginResp } from 'src/app/account/store/models/login.interface';
import { CompSignup } from 'src/app/account/store/models/signup.interface';
import {
  loginFail,
  loginSuccess,
  logoutSuccess,
  resetPassword,
  resetPasswordFail,
  resetPasswordSuccess,
  sendResetPasswordRequest,
  sendResetPasswordRequestFail,
  sendResetPasswordRequestSuccess,
  setBackPath,
  setPhoneNumber,
  setRedirectUrl,
  signup,
  signupFail,
  signupSuccess,
  verifyEmail,
  verifyEmailFail,
  verifyEmailSuccess,
  createUser,
  createUserSuccess,
  createUserFail,
  getProductsSuccess,
  getSubscriptionPlanSuccess
} from 'src/app/account/store/state/account.actions';
import { addSubscriptionSuccess } from 'src/app/dashboard/settings/store/state/settings.actions';
import { ErrorMessages, IProduct } from 'src/app/shared/interface/cardinformation.model';
import { SubscriptionDetails } from '../models/subscription.interface';

export const accountFeatureKey = 'account';

export interface AccountState {
  backPath: BackPath;
  phoneNumber: string | null;
  isRegistrationComplete: boolean | null;
  errorDuringRegistration: ErrorMessages | null;
  signupData: CompSignup | null;
  isResetPasswordRequestSent: boolean | null;
  isPasswordReset: boolean | null;
  isLoggedIn: boolean;
  user: LoginResp | null;
  errorDuringLogin: ErrorMessages | null;
  redirectUrl: string | null;

  verificationState : number;
  verificationParams : ParamMap | null,

  isUserCreated: boolean | null;
  errorDuringCreation: ErrorMessages | null;

  products: IProduct[];
  subscriptionPlan: SubscriptionDetails;
  productCode: string
  
  transformedProducts: Array<{ type: string, value: IProduct[] }>;
}

export const initialState: AccountState = {
  backPath: {
    visible: false,
  },
  phoneNumber: null,
  isRegistrationComplete: null,
  errorDuringRegistration: null,
  signupData: null,
  isResetPasswordRequestSent: null,
  isPasswordReset: null,
  isLoggedIn: false,
  user: null,
  errorDuringLogin: null,
  redirectUrl: null,

  verificationState: 0,
  verificationParams: {} as ParamMap,


  isUserCreated: null,
  errorDuringCreation: null,

  products: [],
  subscriptionPlan: { id: '', collection_method: '', created: 0, current_period_end: 0, current_period_start: 0, customer: '', product: '' } as SubscriptionDetails,
  productCode: 'PSR',
  transformedProducts: [],
};

export const accountReducer = createReducer(
  initialState,
  on(setBackPath, (state, action) => ({
    ...state,
    backPath: action.backPath,
  })),
  on(setPhoneNumber, (state, action) => ({
    ...state,
    phoneNumber: action.phoneNumber,
  })),
  on(signup, (state, action) => ({
    ...state,
    signupData: action.data,
    isRegistrationComplete: null,
  })),
  on(signupSuccess, (state) => ({
    ...state,
    isRegistrationComplete: true,
  })),
  on(signupFail, (state, action) => ({
    ...state,
    isRegistrationComplete: false,
    errorDuringRegistration: action.error
  })),
  on(sendResetPasswordRequest, (state) => ({
    ...state,
    isResetPasswordRequestSent: null,
  })),
  on(sendResetPasswordRequestSuccess, (state) => ({
    ...state,
    isResetPasswordRequestSent: true,
  })),
  on(sendResetPasswordRequestFail, (state) => ({
    ...state,
    isResetPasswordRequestSent: false,
  })),
  on(resetPassword, (state) => ({
    ...state,
    isPasswordReset: null,
  })),
  on(resetPasswordSuccess, (state) => ({
    ...state,
    isPasswordReset: true,
  })),
  on(resetPasswordFail, (state) => ({
    ...state,
    isPasswordReset: false,
  })),
  on(loginSuccess, (state, action) => ({
    ...state,
    isLoggedIn: true,
    user: action.user,
  })),
  on(loginFail, (state, action) => ({
    ...state,
    isLoggedIn: false,
    errorDuringLogin: action.error
  })),
  on(addSubscriptionSuccess, (state, action) => ({
    ...state,
    user: action?.updateUserInfo ? action.updateUserInfo : state.user,
  })),
  on(setRedirectUrl, (state, action) => ({
    ...state,
    redirectUrl: action.redirectUrl,
  })),
  on(logoutSuccess, () => ({
    ...initialState,
    isLoggedIn: false,
    user: null
  })),


  on (verifyEmail, (state, action)=>({
    ...state,
    verificationState: 0,
    verificationParams: action.params
  })),

  on (verifyEmailSuccess, (state)=>({
    ...state,
    verificationState: 1
  })),

  on (verifyEmailFail, (state)=>({
    ...state,
    verificationState: 2
  })),



  on(createUser, (state) => ({
    ...state,
    isUserCreated: null,
  })),

  on(createUserSuccess, (state) => ({
    ...state,
    isUserCreated: true,
  })),
  on(createUserFail, (state, action) => ({
    ...state,
    isUserCreated: false,
    errorDuringCreation: action.error,
  })),


  on(getProductsSuccess, (state, action) => ({
    ...state,
    products: action.products,
    productCode: getProductKey(action.products, state.subscriptionPlan),
    transformedProducts: transformProducts(action.products)

  })),

  
  on(getSubscriptionPlanSuccess, (state, action) => ({
    ...state,
    subscriptionPlan: action.subscriptionPlan,
    productCode: getProductKey(state.products, action.subscriptionPlan)
  })),

);

function getProductKey(products: IProduct[], subscriptionDetails: SubscriptionDetails) {

  let subscribedProduct = products.filter((product) => product.id === subscriptionDetails.product);

  
  let product_code = subscribedProduct[0]?.product_code || 'PSR';

  return product_code;


}

function transformProducts(db: IProduct[]) {
  const payAsYouGo = db.filter(item => item.interval === null);
  const unlimited = db.filter(item => item.interval !== null);

  return [
    {
      type: 'payGo',
      value: payAsYouGo,
    },
    {
      type: 'unlimited',
      value: unlimited
    }
  ];
}
