import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as AccountReducer from 'src/app/account/store/state/account.reducer';

export const selectAccountState = createFeatureSelector<AccountReducer.AccountState>(AccountReducer.accountFeatureKey);

export const getBackPath = createSelector(selectAccountState, (state) => state.backPath);
export const getPhoneNumber = createSelector(selectAccountState, (state) => state.phoneNumber);
export const isRegistrationComplete = createSelector(selectAccountState, (state) => state.isRegistrationComplete);
export const getErrorDuringRegistration = createSelector(selectAccountState, (state) => state.errorDuringRegistration);
export const getSignupData = createSelector(selectAccountState, (state) => state.signupData);
export const isResetPasswordRequestSent = createSelector(selectAccountState, (state) => state.isResetPasswordRequestSent);
export const getUserInfo = createSelector(selectAccountState, (state) => state.user);
export const getUserType = createSelector(selectAccountState, (state) => state.user?.user_type);
export const getToken = createSelector(selectAccountState, (state) => state.user?.token);
export const getErrorDuringLogin = createSelector(selectAccountState, (state) => state.errorDuringLogin);
export const getRedirectUrl = createSelector(selectAccountState, (state) => state.redirectUrl);
export const isLoggedIn = createSelector(selectAccountState, (state) => state.isLoggedIn);
export const getVerificationState = createSelector (selectAccountState, (state) => state.verificationState);
export const isUserCreated = createSelector(selectAccountState, (state) => state.isUserCreated);
export const getErrorDuringCreation = createSelector(selectAccountState, (state) => state.errorDuringCreation);


export const getAllProducts = createSelector(selectAccountState, (state) => state.products);
export const getAllSubscriptionPlan = createSelector(selectAccountState, (state) => state.subscriptionPlan);

export const getProductKey = createSelector(selectAccountState, (state) => state.productCode);

export const getTransformedProducts = createSelector(selectAccountState, (state) => state.transformedProducts);
