import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

type KEYS = 'TOKEN' | 'LANG' | 'SEARCHQUERY' | 'SEARCHRESULT' | 'RISKMETER' | 'INFO'

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private storageSub = new Subject<string>();

  watchStorage(): Observable<any> {
    return this.storageSub.asObservable();
  }

  setItem(key: KEYS, data: string): void {
    localStorage.setItem(key, data);
    this.storageSub.next(key);
  }

  getItem(key: KEYS): any {
    return localStorage.getItem(key);
  }

  clear(): void {
    localStorage.clear();
  }
}
