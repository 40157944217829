export const environment = {
  production: false,

  baseUrl: 'https://ae-dev.checkaml.com/v1/',

  baseEU: 'https://eu-dev.checkaml.com',
  baseAE: 'https://ae-dev.checkaml.com',

  // baseEU: 'http://eu-dev.checkaml.local:4200',
  // baseAE: 'http://ae-dev.checkaml.local:4200',

  subdomain: 'ae',

  version: '1.0.2-DEV',
};
