import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AccountEffects } from 'src/app/account/store/state/account.effects';
import { accountFeatureKey, accountReducer } from 'src/app/account/store/state/account.reducer';
import { usersFeatureKey, usersReducer } from 'src/app/dashboard/users/store/state/users.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(accountFeatureKey, accountReducer),
    EffectsModule.forFeature([AccountEffects]),
  ],
})
export class AccountStoreModule {}
