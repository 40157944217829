import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HistoryGuard } from './account/guards/history.guard';
import { ActivateGuard } from './account/guards/activate.guard';
import {AuthenticationGuard} from './account/guards/authentication.guard'
import { AuthorizationGuard } from './account/guards/authorization.guard';
import { DashboardGuard } from './account/guards/dashboard.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'account',
    pathMatch: 'full'
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
  },
  {
    path: 'activate',
    loadChildren: () => import('./activate/activate.module').then(m=>m.ActivateModule),
  },
  {
    path: 'app',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [DashboardGuard, AuthenticationGuard]
  },

  // {
  //   path: '**',
  //   redirectTo: 'account',
  //   pathMatch: 'full'
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
