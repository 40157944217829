import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginCreds, LoginResp } from 'src/app/account/store/models/login.interface';
import { CompSignup, SubUser } from 'src/app/account/store/models/signup.interface';
import { IProduct } from 'src/app/shared/interface/cardinformation.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private readonly baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  verifyEmail(params: ParamMap) {
    const email = params?.get('email')!; // ⬅ non-null assertion operator
    const token = params?.get('token')!;

    return this.http.get(`${this.baseUrl}company/verify_email`, { params: { email, token } } );
  }

  resendVerifyEmail(email: string) {
    const params = { email };

    return this.http.get(`${this.baseUrl}company/create`,  { params }  );
  }

  signup(data: CompSignup) {
    return this.http.post(`${this.baseUrl}company/create/`, data);
  }

  signupRetry(data: CompSignup) {
    return this.http.post(`${this.baseUrl}company/create/`, data);
  }

  refreshToken(data: {token: string}) {
    return this.http.post(`${this.baseUrl}user/api-token-refresh/`, data);
  }

  sendResetPasswordRequest(email: string) {
    return this.http.post(`${this.baseUrl}user/forgot_password/`, email);
  }

  resetPassword(params: ParamMap, new_password: string) {
    const email = params?.get('email')!; // ⬅ non-null assertion operator
    const token = params?.get('token')!;

    return this.http.post(`${this.baseUrl}user/reset_password/`, { email, token, new_password });
  }

  login(data: LoginCreds): Observable<LoginResp> {
    return this.http.post<LoginResp>(`${this.baseUrl}user/login/`, data);
  }


  createUser(token: string, user: SubUser) {
    return this.http.post<any>(`${this.baseUrl}user/create/`, { ...user, token });
  }

  // successCall () {
  //   return this.http.get('https://reqres.in/api/users?delay=0');
  // }
  // failCall () {
  //   return this.http.post('https://reqres.in/api/register', {email: 'sydney@fife'});
  // }


  getProducts(): Observable<IProduct[]> {
    return this.http.get<IProduct[]>(`${this.baseUrl}product/list/`);
  }

  getSubscriptionPlan(): Observable<any> {
    return this.http.get<any[]>(`${this.baseUrl}subscription/subscription/`);
  }
}
