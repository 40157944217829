import { ParamMap } from '@angular/router';
import { createAction, props } from '@ngrx/store';
import { BackPath } from 'src/app/account/store/models/back-path.interface';
import { LoginCreds, LoginResp } from 'src/app/account/store/models/login.interface';
import { CompSignup, SubUser } from 'src/app/account/store/models/signup.interface';
import { ErrorMessages, IProduct } from 'src/app/shared/interface/cardinformation.model';



export const verifyEmail = createAction('[Account] verify email', props<{ params: ParamMap }>());

export const verifyEmailSuccess = createAction('[Account] verify email success');

export const verifyEmailFail = createAction('[Account] verify email fail');

export const signup = createAction('[Account] signup', props<{ data: CompSignup }>());

export const signupSuccess = createAction('[Account] signup success');

export const signupFail = createAction('[Account] signup fail', props<{ error: ErrorMessages }>());

export const signupRetry = createAction('[Account] signup retry', props<{ data: CompSignup }>());

export const signupRetrySuccess = createAction('[Account] signup retry success');

export const signupRetryFail = createAction('[Account] signup retry fail');

export const sendResetPasswordRequest = createAction('[Account] send reset password request', props<{ email: string }>());

export const sendResetPasswordRequestSuccess = createAction('[Account] send reset password request success');

export const sendResetPasswordRequestFail = createAction('[Account] send reset password request fail');

export const resetPassword = createAction('[Account] reset password request', props<{ params: ParamMap, newPassword: string }>());

export const resetPasswordSuccess = createAction('[Account] reset password request success');

export const resetPasswordFail = createAction('[Account] reset password request fail');

export const login = createAction('[Account] login request', props<{ data: LoginCreds }>());

export const loginSuccess = createAction('[Account] login request success', props<{ user: LoginResp }>());

export const loginFail = createAction('[Account] login request fail', props<{ error: ErrorMessages }>());

export const resendVerifyEmail = createAction('[Account] resend verify email', props<{ email: string }>());

export const resendVerifyEmailSuccess = createAction('[Account] resend verify email success');

export const resendVerifyEmailFail = createAction('[Account] resend verify email fail');

export const logout = createAction('[Account] logout');
export const logoutSuccess = createAction('[Account] logout success');

export const setBackPath = createAction('[Account] set back path', props<{ backPath: BackPath }>());
export const setPhoneNumber = createAction('[Account] set phone number', props<{ phoneNumber: string }>());
export const setRedirectUrl = createAction('[Account] set redirect url', props<{ redirectUrl: string }>());

export const createUser = createAction('[Account] create user', props<{ token: string, user: SubUser }>());
export const createUserSuccess = createAction('[Account] create user success');
export const createUserFail = createAction('[Account] create user fail', props<{ error: ErrorMessages }>());

export const getProducts = createAction('[Account] get products');
export const getProductsSuccess = createAction('[Account] get products success', props<{ products: IProduct[] }>());
export const getProductsFail = createAction('[Account] get products fail');
export const getSubscriptionPlan = createAction('[Account] get subscription');
export const getSubscriptionPlanSuccess = createAction('[Account] get subscription success', props<{ subscriptionPlan: any }>()); //her
export const getSubscriptionPlanFail = createAction('[Account] get subscription fail');